<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20">
        <FormItem>
          <span class="label">订单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight70" v-model="queryFrom.order_code"></i-input>
        </FormItem>
        <FormItem>
          <span class="label">客户名称：</span
          ><Select class="iviewIptWidth250 marginRight70" filterable clearable v-model="supplierIndex">
            <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">状态：</span
          ><Select class="iviewIptWidth250 marginRight70" v-model="queryFrom.order_status" clearable @on-change="stateChange">
            <Option v-for="item in orderStateList" :value="item.value" :key="item.value + item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">订单类型：</span
          ><Select class="iviewIptWidth250" v-model="queryFrom.order_type" clearable>
            <Option v-for="item in orderTypeList" :value="item.value" :key="item.value + item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <br />
        <FormItem>
          <span class="label">下单时间：</span>
          <DatePicker :editable="false" class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker><span class="choseTimeZhi">至</span><DatePicker :editable="false" :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addOrder" v-if="insertAble">新增</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table @on-sort-change="sort" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          <div>
            <span>合计金额：</span>
            <span class="color389">¥{{ totalMoney }}</span>
          </div>
        </div>
      </Table>
    </div>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureDelete"></tips-component>
    <!-- 状态 部分发货 关闭 -->
    <tips-component showModal="close" v-if="partialStatus" @cancleBtn="partialStatus = false" @sureBrn="closeOrder"></tips-component>
    <!--     反冲-->
    <tips-component showModal="recoil" v-if="recoilStatus" @cancleBtn="recoilStatus = false" @sureBrn="sureRecoil"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name: 'salesOrderConsign',
  components: {
    Table,
    TipsComponent,
  },
  computed: {
    ...mapState(['supplierNameList', 'clientNameList']),
  },
  data() {
    return {
      billNumber: '',
      paging: null,
      clickId: null,
      status: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 4,
      typeList: [],
      partialStatus: false,
      recoilStatus: false,
      supplierIndex: -1,
      tipStatus: false,
      totalMoney: 0,
      isLoad: true,
      navFrist: [
        {
          name: '全部',
          num: 140,
          type: '-2',
        },
        {
          name: '草稿',
          num: 40,
          type: '-1',
        },
        {
          name: '待发货',
          num: 40,
          type: '1',
        },
        {
          name: '待开票',
          num: 40,
          type: '3',
        },
      ],
      clickIndex: '-2',
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '订单号',
          align: 'center',
          key: 'order_code',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                param.row.order_code
              ),
            ])
          },
          width: 170,
        },
        {
          title: '客户名称',
          align: 'center',
          key: 'customer_name',
          minWidth: 150,
          Width: 300,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.customer_name
              ),
            ])
          },
        },
        {
          title: '订单金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.order_price ? '¥' + param.row.order_price : '0')])
          },
          width: 140,
        },
        {
          title: '下单时间',
          key: 'creatTime',
          sortable: 'custom',
          align: 'center',
          width: 180,
        },
        {
          title: '订单类型',
          key: 'order_type',
          align: 'center',
          width: 140,
        },
        {
          title: '备注',
          align: 'center',
          key: 'remark',
          minWidth: 160,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.remark
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'order_status',
          align: 'center',
          width: 110,
        },
        {
          title: '操作',
          align: 'center',
          width: 240,
          render: (h, param) => {
            let status = param.row.order_status
            if (status == '草稿') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.editRow(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else if (status == '待反冲' || status == '待发货') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.order_code
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_code
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '待反冲') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.order_code
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_code
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '部分发货') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginRight: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.upload(param.row)
                        // this.$Message.warning('功能建设中...')
                      },
                    },
                  },
                  '导出销售单'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.partialStatus = true
                        this.partSendId = param.row.order_code
                      },
                    },
                  },
                  '关闭'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.order_code
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_code
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      // marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.order_code
                        this.status = true
                        // 获取单据编号
                        this.billNumber = param.row.order_code
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
          },
        },
      ],
      partSendId: null,
      listData: [],
      total: 1,
      orderTypeList: [
        {
          name: '普通销售',
          value: 1,
        },
        {
          name: '自寄售',
          value: 2,
        },
        {
          name: '供方寄售',
          value: 3,
        },
        {
          name: '委托代销',
          value: 4,
        },
      ],
      orderStateList: [
        {
          name: '草稿',
          value: '-1',
        },
        {
          name: '待发货',
          value: '1',
        },
        {
          name: '待反冲',
          value: '5',
        },
        {
          name: '部分发货',
          value: '2',
        },
        {
          name: '待开票',
          value: '3',
        },
        {
          name: '已完成',
          value: '4',
        },
      ],
      queryFrom: {
        page: 1,
        rows: 10,
        order_code: '',
        customer_id: '',
        customer_type: '',
        order_time_begin: '',
        order_time_end: '',
        order_type: '',
        order_status: '',
      },
      options: {},
      delectInfo: {}, // 暂存删除数据
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    let flag = this.$route.query.select
    if (flag) {
      this.queryFrom.home_click = '1'
    }
    this.query()
    this.getRightVisibal()
  },
  methods: {
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type}, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate,{template_type: this.template_type},true).then((res) => {
        if(res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 打印模板
    surePrint() {
      let api_info = `${this.$apiConsign.saleOrderDetails.url}?order_code=${this.clickId}`
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = `${this.billNumber}.xlsx`
        aLink.click()
      })
    },
    closeOrder() {
      this.$http.put(this.$apiConsign.saleOrderClose, { order_code: this.partSendId }, true).then(res => {
        this.partialStatus = false
        this.$Message.success('关闭成功！')
        this.query()
      })
    },
    sort(data) {
      console.log(data)
      if (data.key === 'creatTime') {
        this.queryFrom.sort_str = 'order_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    sureRecoil() {
      this.$http.put(this.$apiConsign.saleOrderRecoil, { order_code: this.delectInfo.order_code }, true).then(res => {
        this.recoilStatus = false
        this.query()
      })
    },
    // 点击反冲按钮
    recoil(row) {
      this.delectInfo = row
      this.recoilStatus = true
    },
    // 编辑
    editRow(row) {
      this.$router.push({
        path: '/salesOrderAddConsign',
        query: {
          id: row.order_code,
        },
      })
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$apiConsign.saleOrder, { order_code: this.delectInfo.order_code }, true).then(res => {
        this.query()
        this.tipStatus = false
      })
    },
    // 点击删除按钮
    deleteRow(row) {
      this.delectInfo = row
      this.tipStatus = true
    },
    // 状态改变
    stateChange(e) {
      if (e == '-1' || e == '1' || e == '2') {
        this.clickIndex = e
      } else {
        this.clickIndex = '-2'
      }
      this.query()
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.order_time_begin = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.order_time_begin) - 86400000
          },
        }
      } else {
        this.queryFrom.order_time_end = e
      }
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.clientNameList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.clientNameList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      this.$http.get(this.$apiConsign.saleOrder, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_price
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].order_time ? this.$moment(res.data[i].order_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        for (let i = 0; i < res.tab_count.length; i++) {
          this.navFrist[i].num = res.tab_count[i]
        }
      })
    },
    upload(row) {
      this.$http.downFile(this.$apiConsign.saleOrderExport, { order_code: row.order_code }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '销售订单' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    ship() {
      this.$router.push({ path: '/orderShip' })
    },
    goDetails(row) {
      this.$router.push({
        path: '/salesOrderDetailsConsign',
        query: {
          id: row.order_code,
        },
      })
    },
    addOrder() {
      this.$router.push({ path: '/salesOrderAddConsign' })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    clickNav(type) {
      this.clickIndex = type
      if (type != '-2') {
        this.queryFrom.order_status = type
      } else {
        this.queryFrom.order_status = ''
      }
      this.queryFrom.page = 1
      this.queryList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style scoped lang="less">
.item {
  margin-bottom: 20px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 90px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
